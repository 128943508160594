import React, { useRef } from 'react'
import Flickity from 'react-flickity-component'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import Button from 'components/button'

export default ({
    heading,
    resourcesList,
    resourcesSlider
}) => {
    const sliderRef = useRef(null)

    if(!resourcesList || !resourcesSlider){
        return null
    }

    return (
        <div className="resources-grid">
            <div className="container">
                <div className="heading-row">
                    <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                </div>
                <div className="columns">
                    <div className="slider-container">
                        { typeof window != 'undefined' &&
                            <Flickity
                                className={'carousel'}
                                options={{
                                    wrapAround: true,
                                    groupCells: 1,
                                    prevNextButtons: false,
                                    pageDots: true,
                                    adaptiveHeight: true,
                                    autoPlay: 7000
                                }}
                                flickityRef={ref => sliderRef.current = ref}
                            >
                                {resourcesSlider.map(({resource}, index) => {
                                    const {
                                        featuredImage,
                                        title,
                                        resourceFields
                                    } = resource

                                    const backgroundUrl = featuredImage && featuredImage.mediaItemUrl 

                                    return (
                                        <div className="slide" key={index}>
                                            <div
                                                className="image"
                                                style={{
                                                    backgroundImage: `url(${ backgroundUrl })`
                                                }}
                                            >
                                                <div className={'progress'} />
                                            </div>
                                            <h3>
                                                { title }
                                            </h3>
                                            <p>
                                                { resourceFields.excerpt }
                                            </p>
                                            <Button 
                                                label={'Read more'}
                                                url={resourceFields.externalLink}
                                                target="_blank"
                                            />
                                        </div>
                                    )
                                })}
                            </Flickity>
                        }
                    </div>
                    <div className="useful-resources-container">
                        <h4>
                            Useful resources
                        </h4>
                        <ul className="resources">
                            {resourcesList.map(({ resource }, index) => {
                                const {
                                    title,
                                    resourceFields,
                                } = resource

                                return (
                                    <li key={index}>
                                        <a href={resourceFields.externalLink} target="_blank">
                                            <p className="bold">
                                                { title }
                                            </p>
                                            <Button 
                                                label={'View resource'}
                                                icon={'arrow-up-right'}
                                            />
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}