import React, { useEffect, useState, useRef } from "react"
import Slider from 'react-flickity-component'
import 'flickity/css/flickity.css'

import { useIsMobile } from 'utils'

export default ({
    heading
}) => {
    const isMobile = useIsMobile

    const [images, setImages] = useState([])

    useEffect(() => {
        const instagramHandle = `coburgnightmarket`

        const request = new XMLHttpRequest()
        request.open('GET', 'https://www.instagram.com/' + instagramHandle + '/?__a=1', true)

        request.onload = () => {
            if (request.status >= 200 && request.status < 400) {
                if (!request.responseText.startsWith('<')) {
                    const data = JSON.parse(request.responseText)
                    const imageData = data.graphql.user.edge_owner_to_timeline_media.edges
                    let _images = []
                    imageData.forEach(({ node: image }) => {
                        _images.push({
                            src: image.thumbnail_src,
                            url: `https://instagram.com/p/${ image.shortcode }`
                        })
                    })
                    _images = _images.slice(0, 20)
                    setImages(_images)
                }
            }
        }

        request.send()
    }, [])

    const sliderInstagramRef = useRef(null)

    const flickityOptions = {
        wrapAround: true,
        prevNextButtons: true,
        pageDots: false,
        cellAlign: isMobile ? 'center' : 'left',
        autoPlay: 3000
    }

    return (
        <div className="instagram">
            <h2 dangerouslySetInnerHTML={{ __html: heading }} />
            <div className="container">
                <div className="slider-container">
                    { typeof window != 'undefined' &&
                        <Slider
                            className={'feed'}
                            options={flickityOptions}
                            flickityRef={ref => sliderInstagramRef.current = ref}
                        >
                            { images.map((image, index) => {
                                return (
                                    <div className={'slide'} key={index}>
                                        <a href={image.url} target="_blank">
                                            <img src={image.src} alt="Instagram feed image" />
                                        </a>
                                    </div>
                                )
                            })}
                        </Slider>
                    }
                </div>
            </div>
        </div>
                            
    )
}