import React, { useContext, useState, useEffect } from 'react'
import Form from 'components/form'
import { GET_SUBMISSIONS } from '../graphql/queries'
import { SUBMIT_FORM } from '../graphql/queries'
import { useMutation } from '@apollo/client'
import { MainContext } from "context/context"
import { useLazyQuery } from '@apollo/client'
import SpeechBubble from '../images/speech-bubble.png'
import Loader from 'components/loader'
import ShareTheLoveSlider from './shareTheLoveSlider'

export default ({
    heading,
    copy,
    form
}) => {
    const [getSubmissions, {
        data: submissionsData,
        loading: submissionsLoading,
        error
    }] = useLazyQuery(GET_SUBMISSIONS, {
        fetchPolicy: "network-only"
    })

    const {
        shareRef,
        openModal,
        closeModal
    } = useContext(MainContext)

    const [submitForm] = useMutation(SUBMIT_FORM)
    const [isLoading, setIsLoading] = useState(false)
    const [forceLoading, setForceLoading] = useState(false)

    const handleSubmit = data => {
        const json = JSON.stringify(data)
        setIsLoading(true)

        submitForm({
            variables: {
                data: json
            },
        }).then(() => {
            setIsLoading(false)

            getSubmissions()

            openModal({
                type: 'SHARE',
            })

            setTimeout(() => {
                closeModal()
            }, 3000)
        }).catch(error => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getSubmissions()

        const clearSliderContentsInterval = setInterval(() => {
            setForceLoading(true)

            setTimeout(() => {
                setForceLoading(false)
            },1000)
        },240000)

        return () => {
            clearInterval(clearSliderContentsInterval)
        }
    }, [])

    return (
        <div className="share-the-love" ref={ref => shareRef.current = ref}>
            <div className="container">
                <div className="grid">
                    <div className="text-and-form">
                        <h2 dangerouslySetInnerHTML={{ __html: heading }} />
                        <div className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                        <Form
                            form={form}
                            handleSubmit={handleSubmit}
                            isLoading={isLoading}
                        />
                    </div>
                    <div className="featured">
                        <div
                            className="speech-bubble"
                            style={{
                                backgroundImage: `url(${SpeechBubble})`
                            }}
                        >
                            <div className="heading-container">
                                <h3>
                                    Who is your <em>local <span>?</span></em>
                                </h3>
                            </div>
                            <Loader visible={submissionsLoading || forceLoading} />
                            { !forceLoading ?
                                <ShareTheLoveSlider
                                    submissionsData={submissionsData}
                                />
                            : null }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}