import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default ({
   title,
   hideSection,
   backgroundColour,
}) => {
    const [emailValue, setEmailValue] = useState('')
    const [email, setEmail] = useState('')
    const [formMsg, setFormMessage] = useState('')

    const handleSubmit = e => {

        e.preventDefault()

        addToMailchimp(email)
        .then(data => {
            
            setFormMessage( '<p>' + data.msg + '<p>')

            if (data.result === 'success') {
                setEmail('')
                setEmailValue('')
            }

        })
        .catch(() => {})
    }

    const handleEmailChange = (event) => {
        
        setEmail(event.currentTarget.value)
        setEmailValue(event.currentTarget.value)

    }

    return (
        <div className={ backgroundColour === 'lightblue' ? 'Subscribe-outside lightblue' : 'Subscribe-outside' }>
            <div className={ hideSection ? 'Subscribe-cont hidden' : 'Subscribe-cont' }>
                <div className="container">
                    <div className="Subscribe-text">
                        <h3 dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                    <form onSubmit={ handleSubmit }>
                        <input type="email" placeholder="Enter your email address" value={ emailValue } onChange={ handleEmailChange } />
                        <button type="submit" className="button border">Subscribe</button>
                        <div dangerouslySetInnerHTML={{ __html: formMsg }} />
                    </form>
                </div>
            </div>
        </div>
    )
}
