import React, { useContext, useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Button from 'components/button'
import 'vendor/flickity-as-nav-for/as-nav-for'
import 'flickity/css/flickity.css'
import Slider from 'components/slider'
import { MainContext } from 'context/context'

import MapIcon from '../images/map-icon.png'

import StickerA from '../images/stickers/a.png'
import StickerB from '../images/stickers/b.png'
import StickerC from '../images/stickers/c.png'

import { useIsMobile } from 'utils'

export default ({
    heading,
    copy,
    businesses,
    logo,
    hideSection,
}) => {
	const {
        openModal,
		options,
    } = useContext(MainContext)

	const {
		logos,
	} = options

	const [filterDate, setFilterDate] = useState()
	const [showAll, setShowAll] = useState(false)

	const datesQuery = useStaticQuery(graphql`
        query datesQuery {
            siteFields {
                dates {
                    nodes {
                        name
                        slug
                    }
                }
            }
        }
    `)
    
    const dates = datesQuery.siteFields.dates.nodes

    const _dates = dates.sort((a, b) => {
        return new Date(a.name + '-2020') - new Date(b.name + '-2020')
    })

    const isMobile = useIsMobile()

    const openStory = business => {
        if(business && business.title){
            openModal({
                type: 'BUSINESS',
                data: business
            })
        }
    }

    const _setFilterDate = (date) => {
        setShowAll(true)
        setFilterDate(date)
    }


    let businessCounter = 0

    const businessesJsx = businesses.map(({ business, useModal }, index) => {
        if(!business){
            return null
        }
        
        const {
            title,
            dates,
            businessFields,
            featuredImage,
        } = business

        let hideBusiness = false

        if (filterDate) {
            hideBusiness = true
            dates.nodes.map((date) => {
                if (filterDate === date.slug) {
                    hideBusiness = false
                }
            })
        }

        businessCounter++

        const businessClassName = businessCounter > 6 && !showAll ? ' hidden date-hide-' + hideBusiness : 'date-hide-' + hideBusiness

        return (
            <li 
                key={index}
                style={{
                    transitionDelay: (index/10 + 0.7)+'s'
                }}
                className={ businessClassName }
            >
                <a href={ !useModal ? businessFields.website : 'Javascript:void(0)' } target={ !useModal ? '_blank' : '' } onClick={ () => { useModal && openStory(business) }}>
                    <div className="image">
                        <div className={ featuredImage ? "image-inner" : "image-inner noImage" } style={{ backgroundImage: `url(${ featuredImage ? featuredImage.mediaItemUrl : logos.inverted.sourceUrl })` }} />
                    </div>
                    <div className="info">
                        <div className="title-row">
                            <h3 className="title" dangerouslySetInnerHTML={{__html: title}} />
                        </div>
                        <p>
                            {businessFields.excerpt}
                        </p>
                        <Button
                            label={ !useModal ? 'Shop now' : 'View more' }
                        />
                    </div>
                </a>
            </li>
        )
    })

    return (
        <div className={ hideSection ? 'showcase hidden' : 'showcase' }>
            <div className="container">
                <div className="top-section">
                    <div className="container">
                        { logo && <img className="background-logo" src={logo.mediaItemUrl} alt="Support local businesses"/> }
                        <h2 dangerouslySetInnerHTML={{__html: heading}} />
                        <div className={'copy'} dangerouslySetInnerHTML={{ __html: copy }} />
                    </div>
                    <ul>
                        {dates.map((date, index) => {
                            return (
                                <li onClick={ () => { _setFilterDate(date.slug) }} key={ index } className={ date.slug === filterDate ? 'active' : '' } data-date={ date.slug } dangerouslySetInnerHTML={{ __html: date.name }} />
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div className="bottom-section no-rtl">
                <div className="container">
                    <ul className="grid">
                        { businessesJsx }
                    </ul>
                    { !showAll &&
                        <Button
                            onClick={ () => { setShowAll(true) }}
                            label={ 'View all' }
                        />
                    }
                </div>
            </div>
        </div>
    )
}