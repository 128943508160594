import React from 'react'
import Button from 'components/button'

export default ({
   columns,
   backgroundColour,
   hideSection,
}) => {
    return (
        !hideSection &&
            <div className={ 'Columns-cont' + ( backgroundColour && ' ' + backgroundColour ) }>
                <div className="container">
                    <ul>
                        {columns.map((column, index) => {
                            const {
                                text,
                                image,
                                link,
                            } = column

                            const imageURL = image && image.mediaItemUrl 

                            return (
                                <li key={index}>
                                    <div dangerouslySetInnerHTML={{ __html: text }} />
                                    { link &&
                                        <Button 
                                            label={ link.title }
                                            url={ link.url }
                                        />
                                    }
                                    { image &&
                                        <img src={ imageURL } />
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
    )
}