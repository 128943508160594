import React from 'react'

export default ({
    logo,
    heading,
    copy,
    backgroundImage,
    backgroundVideo,
    smaller,
}) => {

    const handleScroll = () => {

        const element = document.getElementById('Hero')
        const y = element.getBoundingClientRect().height - 100

        window.scrollTo({ top: y, behavior: 'smooth' })

        return false
    }

    return (
        <div>
            <div className={ smaller ? "hero smaller" : "hero" } style={{ backgroundImage: `url(${ backgroundImage && backgroundImage.mediaItemUrl })` }}>
                <div className="container">
                    <div className={ backgroundVideo ? 'content hasVideo' : 'content' }>
                        { logo &&
                            <div className="hero-logo" style={{ backgroundImage: `url(${ logo.mediaItemUrl })` }} />
                        }
                        <p className="copy" dangerouslySetInnerHTML={{ __html: copy }} />
                        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
                    </div>
                    { backgroundVideo &&
                        <>
                            <video autoPlay muted loop playsInline>
                                <source src={ backgroundVideo.mediaItemUrl } type="video/mp4" />
                            </video>
                            <div className="hero-joinus" onClick={() => handleScroll('SupportActivate')}>
                                <div>Join us!</div>
                                <i class="fe fe-arrow-down" />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}