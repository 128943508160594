import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import { MainContext } from "context/context"

import SEO from "components/seo"
import FlexibleContent from "components/flexibleContent"

export default ({ data }) => {
	const {
		databaseId,
		slug,
		excerpt,
		flexibleContent,
	} = data.siteFields.page

	const {
		pageTitle,
		extraCopy,
		meta
	} = flexibleContent

    const {
		setCurrentPage
	} = useContext(MainContext)
	
	useEffect(() => {
		if(extraCopy){
			setCurrentPage(extraCopy)
		}

	}, [extraCopy])

	return (
		<>
			<SEO 
				title={pageTitle} 
				description={(meta && meta.description) ? meta.description : excerpt}
				metaTitle={(meta && meta.title) ? meta.title : undefined}
				metaImage={(meta && meta.image) ? meta.image : undefined}
			/>
			<FlexibleContent
				id={databaseId}
				content={flexibleContent}
				slug={slug}
			/>
		</>
	)
}

export const query = graphql`
	query($databaseId: ID!) {
		siteFields {
			page(id: $databaseId, idType: DATABASE_ID) {
				databaseId
				title
				slug
				date
				content(format: RENDERED)
				featuredImage {
					altText
					title(format: RENDERED)
					mediaItemUrl
					slug
				}
				flexibleContent {
					pageTitle
					extraCopy {
						footerAcknowledgement
						submissionConfirmation {
							title
							body
						}
					}
					meta {
						title
						description
						image {
							mediaItemUrl
						}
					}
					flexibleContent {
						__typename
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Hero {
							logo {
								mediaItemUrl
							}
							heading
							copy
							backgroundImage {
								mediaItemUrl
							}
							backgroundVideo {
								mediaItemUrl
							}
							smaller
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Instagram {
							heading
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_InteractiveMap {
							heading
							copy
							image {
								mediaItemUrl
							}
							iframeUrl
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ShareTheLove {
							heading
							copy
							form {
								... on WPGraphQL_Form {
									...FormFieldsFragment
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Showcase {
							heading
							copy
							logo {
								mediaItemUrl
							}
							businesses {
								useModal
								business {
									... on WPGraphQL_Business {
										title
										content
										locations {
											nodes {
												name
											}
										}
										dates {
											nodes {
												name
												slug
											}
										}
										featuredImage {
											mediaItemUrl
										}
										businessFields {
											logo {
												mediaItemUrl
											}
											excerpt
											address
											phoneNumber
											website
											emailAddress
											socialLinks {
												type
												url
											}
											images {
												image {
													mediaItemUrl
												}
											}
										}
									}
								}
							}
							hideSection
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Quotes {
							quotes {
								quote 
								location
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_ResourcesGrid {
							heading
							resourcesList {
								resource {
									... on WPGraphQL_Resource {
										title
										featuredImage {
											mediaItemUrl
										}
										resourceFields {
											externalLink
											excerpt
										}
									}
								}
							}
							resourcesSlider {
								resource {
									... on WPGraphQL_Resource {
										link
										title
										date
										featuredImage {
											mediaItemUrl
										} 
										resourceFields {
											excerpt
											externalLink
										}
									}
								}
							}
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Columns {
							columns {
								text
								image {
									mediaItemUrl
								}
								link {
									title
									url
								}
							}
							backgroundColour
							hideSection
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_FullText {
							copy
						}
						... on WPGraphQL_Page_Flexiblecontent_FlexibleContent_Subscribe {
							title
							hideSection
							backgroundColour
						}
					}
				}
			}
		}
	}
`
